export const API_ENDPOINTS = {
  GET_TASK_DETAIL_WITH_ACTION_ITEMS: 'ProjectTask/GetTaskDetaiWithActionItems',
  DELETE_TASK_COMMENT_FROM_MAIN_TASK: 'ProjectTask/DeleteComment?commentId=',
  EDIT_TASK_COMMENT_FROM_MAIN_TASK: 'ProjectTask/EditComment',
  ADD_SUBTASK_FROM_MAIN_TASK: 'ProjectTask/AddUpdateTimeLog',
  LIST_TIME_LOG_API: 'ProjectTask/TimeLogs/',
  DELETE_TIMER: 'ProjectTask/TimeLog/',
  GET_TASK_MEETING: 'ProjectTask/GetTaskMeeting',
  ADD_TASK_MEETING: '/ProjectTask/AddUpdateTaskMeeting',
  DELETE_TASK_MEETING: "ProjectTask/DeleteTaskMeeting",
  GET_ASSOCIATED_ISSUES_Task: 'GetAssociatedIssuesForTask?TaskId=',
  GET_ASSOCIATED_COST_EVENTS_TASK: 'GetAssociatedCostEventsForTask?TaskId=',
  GET_ASSOCIATED_TASK_ISSUE: 'ProjectTask/GetAssociatedTaskForIssue?IssueID=',
  GET_ASSOCIATED_COST_EVENTS_ISSUE: 'GetAssociatedCostEventsForIssue?IssueID=',
  GET_ASSOCIATED_ISSUES_FOR_COST_EVENT: 'GetAssociatedIssuesForCostEvent?CostEventID=',
  GET_ASSOCIATED_TASK_FOR_TAKS_COST_EVENT: 'ProjectTask/GetAssociatedTaskForTaskCostEvents?CostEventID=',
  DIASSOCIATED_LINKED_ITEMS: 'ProjectTask/DeassociateLinkeditems?LinkFrom=',
  TASK_LIST_PROJECTID_WITH_SEARCH: 'ProjectTask/TaskListByProjectIDWithSearch?projectId=',
  GET_ISSUES_PROJECTID_SEARCH: 'GetAllIssuesByProjectIdWithSearch?ProjectID=',
  GET_COSTEVENTS_PROJECTID_SEARCH: 'GetAllCostEventsByProjectIdWithSearch?ProjectID=',
  LINK_ISSUE_COST_EVENT: 'LinkIssueToCostEvent?LinkFrom=',
  LINK_TASK_COST_EVENT: 'LinkTaskToCostEvent?LinkFrom=',
  LINK_TASK_ISSUE: 'LinkTaskToIssue?LinkFrom=',
  GET_CLOSEOUT_ITEMS: 'Closeout/GetCloseoutItems',
  GET_CLOSEOUT_TEMPLATES: 'Closeout/GetAllCloseoutTemplate',
  GET_CLOSEOUT_ITEMS_BY_PROJECTID: 'Closeout/GetProjectCloseoutItemsList/',
  ADD_CLOSEOUT_TO_PROJECT: 'Closeout/AddCloseoutTemplateToProject/',
  DEACTIVATE_CLOSEOUT_TEMPLATE_ENDPOINT: 'Closeout/DeactivateCloseoutTemplate?CloseoutTemplateId=',
  ACTIVATE_CLOSEOUT_TEMPLATE_ENDPOINT: 'Closeout//ActivateCloseoutTemplate?CloseoutTemplateId=',
  CLOSEOUT_DETAILS: 'Closeout/GetCloseoutXProjectDetailById/',
  ADD_CLOSEOUT_FILES: 'Closeout/CreateCloseoutItem/AddCloseoutFiles',
  GET_CLOSEOUT_ITEM_FILES: 'Closeout/GetCloseoutItemFiles?',
  GET_CLOSEOUT_COMMENTS: 'Closeout/GetCloseoutXProjectCommentsWithFIle?',
  ADD_CLOSEOUT_COMMENTS: 'Closeout/AddCloseoutItemComment',
  GET_ACTIVE_CLOSEOUT_TEMPLATES: 'Closeout/GetAllActiveCloseoutTemplate',
  GET_ALL_OWNER_CODE: 'GetAllOwnerCode',
  GET_ALL_ACTIVE_OWNER_CODE: 'GetActiveOwnerCode',
  GET_CSI_DIVISION_CODE: 'File/GetCSI_Division_Code',
  GET_UNIFORMAT_DIVISION_CODE: 'File/GetUniformat_Division_Code',
  GET_ALL_UNIFORMAT_DIVISION_CODE: 'File/GetActiveUniformatDivisonCode',
  CREATE_OWNER_CODE: 'CreateOwnerCode',
  CREATE_CM_GC_CODE: 'CreateCmGcCode',
  ADD_CSI_DIVISION_CODE: 'File/AddCsiDivisionCode',
  ADD_UNIFORMAT_DIVISION_CODE: 'File/AddUniformatDivisionCode',
  EDIT_OWNER_CODE: 'EditOwnerCode',
  EDIT_CM_GC_CODE: 'EditCmGcCode',
  EDIT_CSI_DIVISION_CODE: 'File/EditCsiDivisonCode',
  EDIT_UNIFORMAT_DIVISION_CODE: 'File/EditUniformatDivisonCode',
  ACTIVE_DEACTIVE_OWNER_CODE: 'ActivateDeactivateOwnerCode',
  ACTIVE_DEACTIVE_CM_GC_CODE: 'ActivateDeactivateCmGcCode',
  ACTIVE_DEACTIVE_CSI_DIVISION_CODE: 'File/ActiveDeactiveCsiDivisonCode',
  ACTIVE_DEACTIVE_UNIFORMAT_DIVISION_CODE: 'File/ActiveDeactiveUniformatDivisonCode',
  GET_ALL_CMGC_CODE: 'GetAllCmGcCode',
  GET_Active_CMGC_CODE: 'GetActiveCmGcCode',
  UPDATE_CMGC_SOURCE: 'UpdateBudgetXCmGcSource',
  GET_ALL_PAY_APPLICATION_CATEGORY: 'GetAllPayApplicationCategory',
  GET_ALL_PAY_APPLICATION_TEMPLATE_WISE_BUDGETITEMS: 'GetPayApplicationTemplateXBudgetItems',
  GET_ALL_PAY_APPLICATION_TEMPLATE_WISE_BUDGETITEMS_DETAILS: 'GetPayApplicationTemplateXBudgetItemsDetails',
  GET_ALL_PAY_ACTIVE_APPLICATION_CATEGORY: 'GetActivePayApplicationTemplates',
  GET_ALL_PAY_APPLICATION_TEMPLATES: 'GetAllPayApplicationTemplate',
  ADD_PAY_APPLICATION_CATEGORY: 'AddPayApplicationCategory',
  UPDATE_PAY_APPLICATION_CATEGORY: 'ActivateDeactivatePaymentApplicationTemplate',
  UPDATE_PAY_APPLICATION_TEMPLATE_CATEGORY: 'EditPayApplicationTemplate',
  GET_DOCUMENT_PAY_APPLICATION: 'GetAllPayApplication',
  ADD_PAY_APPLICATION: 'CreatePayApplication',
  EDIT_PAY_APPLICATION: 'EditPayApplication',
  ADD_PAY_APP_COMMENTS: 'AddPayApplicationComment',
  ADD_PAY_APP_FILES: 'AddPayApplicationFiles',
  GET_PAY_APP_COMMENTS: 'GetPayApplicationCommentsWithFile?',
  APPROVE_PAY_APPLICATION: 'ApprovePayApp',
  REJECT_PAY_APPLICATION: 'RejectPayApp',
  DOWNLOAD_PAY_APPLICATION: 'DownloadPayApplicationFile/',
  ADD_PAY_APPLICATION_ATTACHMENT_SHEET: 'AddUpdatePayApplicationAttachmentSheet',
  ADD_PAY_APPLICATION_TEMPLATE: 'AddOrUpdatePayApplicationTemplate',
  ADD_PAY_APPLICATION_CHANGE_ORDER_LIST_SHEET: 'AddUpdatePayApplicationChangeOrderSummary?',
  GET_PAY_APPLICATION_X_ATTACHMENT_SHEET: 'GetPayApplicationXAttachmentSheet',
  GET_PAYAPPLICATION_STATUS_HISTORY: 'GetPayApplicationStatusHistory?payApplicationId=',
  GET_FINANCE_MANAGERS: 'User/Client/FinanceManager',
  SEND_MAIL_TO_FINANCE_MANAGERS: 'SendPayApplicationWorksheetAndCoverToEmail',
  SEND_CLOSE_OUT_MAIL: 'Closeout/SendCloseOutAttachmentToEmail',
  GET_PAYAPPLICATION_DETAILS: 'GetPayApplicationDetailById?',
  GET_PAYAPPLICATION_CHANGE_ORDER_DETAILS: 'GetPayApplicationChangeOrderList?',
  GET_PROJECT_DETAILS: 'LiteGetProjectDetailsByProjectId?',
  GET_LIGHT_PAYAPPLICATION_DETAILS: 'LitePayApplicationDetail?',
  GET_ALL_PAY_APPLICATION_ATTACHMENT_SHEET: 'GetAllPayApplicationAttachmentSheet',
  GET_PAYAPPLICATION_CALCULATION_DETAILS: 'GetPayApplicationCoverCalculation',
  GET_PAYAPPLICATION_PDF_ATTACHMENT: 'GetPayApplicationPDFAttachment',
  GENERATE_CLOSE_OUT_PDF: 'Closeout/GenerateCloseOutPDF',
  GET_PAYAPPLICATION_CHANGE_ORDER_PDF_ATTACHMENT: 'GetPayApplicationPDFChangeOrderList',
  LITE_GET_PROJECT_DETAILS_BY_PROJECTID: 'LiteGetProjectDetailsByProjectId',
  GET_ALL_DIRECTORS: 'User/Client/Directors',
  SEND_PAY_APPLICATION_FOR_APPROVAl: 'SendPayApplicationForApproval',
  GET_ALL_PAY_APPLICATION_TEMPLATE: 'GetAllPayApplicationTemplate',
  GET_PAY_APPLICATION_TEMPLATE_X_BUDGETITEMS: 'GetPayApplicationTemplateXBudgetItems',
  GET_PROJECTS_BUDGETITEMS: 'GetProjectsBudgetInformationByProjectId?projectId=',
  EDIT_PAY_APPLICATION_CATEGORY: 'EditPayApplicationCategory',
  GET_MILESTONE_KPI_DATA: 'PayApplicationMilestoneKPI?projectId=',
  GET_PAYMENT_APPLICATION_SUMMARY_DATA: 'PayApplicationSummaryKPIData?projectId=',
  GET_COMPLETE_TO_DATE_OR_BALANCE_REMAINING_KPI_DATA: 'PayApplicationCompleteToDateOrBalanceRemainingKPIData?projectId=',
  GET_PAYAPPLICATION_PROPORTION_OF_COST: 'PayApplicationProportionOfCostKPIData?projectId=',
  GET_PAYAPPLICATION_DETAILED_WORK: 'PayApplicationDetailedWorkKPIData?projectId=',
  GET_ALL_CONTINGENCY_CHANGE_REQUEST: 'GetAllContingencyChangeRequests?projectId=',
  ADD_UPDATE_CONTINGENCY_CHANGE_REQUEST: 'AddUpdateContingencyChangeRequest',
  GET_CONTINGENCY_CHANGE_REQUEST_DETAIl_BY_ID: 'GetContingencyChangeRequestDetailById',
  GET_CCR_COMMENTS_WITH_FILES: 'GetContingencyChangeRequestCommentsWithFile?',
  ADD_CCR_COMMENTS: 'AddCCRComments',
  ADD_CCR_FILES: 'AddCCRFiles',
  GET_CCR_STATUS_HISTORY: 'GetCCRStatusHistory?ccrId=',
  APPROVE_CCR: 'ApproveContingencyChangeRequest',
  REJECT_CCR: 'RejectContingencyChangeRequest',
  GET_CONTINGENCY_CHANGE_REQUEST_SUMMARY_REPORT: 'GetContingencyChangeRequestSummaryReport/',
  GET_CONTINGENCY_CHANGE_REQUEST_SUMMARY_BY_BUDGETITEM: 'GetContingencyChangeRequestByBudgetItem/',
  GET_APPROVED_VS_PENDING_CCR_KPI: 'GetApprovedAndPendingCCRKPI?projectId=',
  GET_AVAILABLE_CM_CONTINGENCY_VS_OWNER_CONTINGENCY: 'GetAvailableCMContingencyVsOwnerContingencyKPI?projectId=',
  GENERATE_CCR_PDF: 'GenerateCCRPDF',
  GENERATE_CLAIM_PDF: 'Asset/GenerateClaimDetailPDF',
  GENERATE_ASSET_DETAIL_PDF: 'Asset/GenerateProjectAssetDetailPDF',
  SEND_CCR_MAIL: 'SendCCRAttachmentToEmail',
  GET_AVAILABLE_ASSETS: 'Asset/GetAvailableAssets',
  ADD_WARRANTY_TO_ASSETS: 'Asset/AddWarrantyToAssets',
  UPDATE_WARRANTY_TO_ASSETS: 'Asset/EditWarrantyRepairClaim?ClaimId=',
  GET_WARRANTY_CLAIM_HISTORY: 'Asset/GetWarrantyClaimHistory',
  GET_ASSETS_WARRANTY_HISTORY: 'Asset/GetAssetWarrantyHistory',
  GET_CLAIM_FILE: 'Asset/GetClaimFile/',
  ADD_CLAIM_COMMENT: 'Asset/AddClaimComments',
  ADD_CLAIM_FILES: 'Asset/AddClaimFiles',
  GET_CLAIM_COMMENTS_WITH_FILE: 'Asset/GetClaimCommentsWithFile?claimId=',
  GET_PROJECT_WARRANTIES_CLAIMS: 'GetProjectWarrantyClaims?',
  ADD_ASSETS_TO_PROJECT: 'AddAssetToProject',
  GET_PROJECT_ASSET: 'GetProjectAssets?',
  REMOVE_PROJECT_ASSET: 'RemoveProjectAsset',
  GET_AVAILABLE_ASSET: 'GetAvailableAssets',
  GET_WARRANTY_CLAIM_DETAILS: '/Asset/GetClaimDetailById?claimId=',
  SEND_WARRANTY_HISTORY_MAIL: 'Asset/SendClaimAttachmentEmail',
  SEND_PROJECT_ASSET_ATTACHMENT_MAIL: 'Asset/SendProjectAssetAttachmentEmail',
  GET_PROJECT_DETAILS_BY_ID: 'Asset/GetProjectAssetDetailById?assetId=',
  GET_ASSETS_BY_ID: 'Asset/GetAssetById?AssetId=',
  GET_ASSETS_WARRANTY_HISTORY_BY_ID: 'Asset/GetAssetWarrantyHistory?assetId=',
  GET_WARRANTY_CLAIM_HISTORY_BY_ID: 'Asset/GetWarrantyClaimHistory?claimId=',
  GET_CLAIM_STATUS_HISTORY: 'Asset/GetWarrantyClaimApprovalHistory/',
  APPROVE_REJECT_WARRANTY_CLAIM: 'Asset/ApproveRejectWarrantyClaim',
  GET_PENDING_AUTO_CREATED_COST_EVENTS: 'GetPendingAutoCreatedCE',
  BULK_UPDATE: 'BulkUpdateCostEventRequest',
  SERVICE_TYPE: 'ServiceType',
  CONTRACT_TYPE: 'ContractType',
  ACTIVE_COSTEVENT_TYPE: 'Project/Issue/ActiveCostEventType',
  COSTEVENT_REASON: 'CostEventReason',
  VENDOR_LIST: 'Vendor?Status=Active',
};

export const ROUTE_CONSTANT = {
  VIEW_TASK_DETAIL: 'general-task-by-user/view/',
  VIEW_ACTION_ITEM: 'general-task-by-user/view/action-item/',
  VIEW_PROJECT_TASK_DETAIL: 'projects/view/',
  VIEW_PROJECT_ACTION_ITEM: 'projects/view/action-item/',
  VIEW_COST_EVENT: 'costevents/ceinfo/',
  VIEW_ISSUE: 'projects/issues/view/',
  CLOSEOUT_DETAILS_ROUTE: 'closeout/closeOutDetails/',
  PROJECT_CLOSEOUT_DETAILS_ROUTE: 'projects/viewCloseoutDetail/',
  PROJECT_CLOSEOUT_BALL_IN_COURT_ROUTE: 'KPI/GetCloseOutTypeKPI',
  UPDATE_BUDGET_CATEGORY: 'EditBudgetCategory',
  ADD_BUDGET_CATEGORY: 'AddBudgetCategory',
  VIEW_COSTEVENT_INFO: '/costevents/ceinfo/',
  VIEW_PROJECT_INFO: 'projects/',
  ADD_PAY_APPLICATION_CATEGORY: '/projects/createPayApplicationCategory/',
  EDIT_PAY_APPLICATION_CATEGORY: '/projects/editPayApplicationCategory/',
  VIEW_PAY_APPLICATION_CATEGORY: '/projects/viewPayApplicationCategory/',
  ADD_ATTACHMENT_SHEET_PAY_APPLICATION: '/projects/payapplicationAttachmentsheet/',
  ADD_CHANGE_ORDER_LIST: '/projects/changeOrderList/',
  ADD_ASSET: 'Asset/AddAsset/',
  EDIT_ASSET: 'Asset/EditAsset',
  VIEW_CCR: '/ccrList/viewCCRDetail/',
  ADD_CCR: '/ccrList/createCCR/',
  EDIT_CCR: '/ccrList/editCCR/',
  WARRANTY_CLAIMS_DETAILS: '/WarrantyDetails/ViewWarrantyClaimDetail/',
  ASSETS_DETAILS: 'AssetDetail/'
};

export const STORAGE = {
  CURRENT_PROJECT_TAB: 'currentProjectTab',
  TIMER_DETAILS_WITH_TASK: 'timerDetailsWithtask'
};

export const MESSAGE = {
  PAUSE_TIMER: 'Pause Timer',
  RESUME_TIMER: 'Resume Timer',
  POPOVER_DELETE_TIMELOG: 'Are you sure you want to delete this logged time?',
  POPOVER_DELETE_COMMENT: 'Are You Sure Want To Delete This Comment?',
  POPOVER_DELETE_DOCUMENT: 'Are you sure want to delete this document?',
  TIMELOG_UPDATED: 'Time log updated successfully',
  ACTIONITEM_WARNING: 'Action items cannot have standalone time logs. Please clear the data before adding action items.',
  POPOVER_DELETE_TASK: 'Deleting this task will remove associated time log entries and action items.',
  POPOVER_ACTIVE: 'Are you sure want to activate this task?',
  POPOVER_DEACTIVE: 'Are you sure want to deactivate this task?',
  POPOVER_DELETE_ACTION: 'Are you sure want to delete this task?',
  POPOVER_DELETE_PROJECT_TASK: 'Are you sure want to delete this project task?',
  EMPTY_GUID: '00000000-0000-0000-0000-000000000000',
  TIMER_RUNNING: 'Timer is on for one task',
  LOGGED_TIME_ON_LOGOUT: 'Logged Time at Logout',
  TIMER_STOPPED: 'Timer stopped successfully',
  ADD_TIME: 'Add Time Log',
  TASK_CREATE: 'Task Item created successfully',
  START_TIMER_MESSAGE: 'Timer started successfully',
  POPOVER_DELETE_EVENT: 'The meeting has been deleted. Would you like to delete the associated task as well?',
  CREATE_TASK_OF_MEETING: 'The meeting has been created. Would you like to create the associated task as well?',
  DISASSOCIATE_LINK: 'Are you sure want to disassociate?​',
  KEYDATE_ADD_FIELD_LABEL: 'Key Date label added successfully',
  KEYDATE_EDIT_FIELD_LABEL: 'Key Date label updated successfully',
  DUPLICATE_KEY_FIELD_LABEL: 'Duplicate Field label',
  CLOSEOUT_TEMPLATE_DEACTIVATED: 'CloseOut Template has been deactivated',
  CLOSEOUT_TEMPLATE_ACTIVATED: 'CloseOut Template has been activated',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  NOT_STARTED: 'In Progress',
  IN_PROGRESS: 'Approved',
  COMPLETED: 'Rejected',
  BALL_IN_COURT: 'Ball In Court',
  ADD_BUDGET_CATEGORY: 'Budget Categoty has been added',
  EMAIL_CREATED: 'Email has been created',
  UPDATE_BUDGET_CATEGORY: 'Budget Categoty has been updated',
  UPDATE_BUDGET_CATEGORY_STATUS: 'Budget Categoty status has been updated',
  UPDATE_CM_GC_STATUS: 'CM GC status has been updated',
  UPDATE_OWNER_STATUS: 'Owner status has been updated',
  UPDATE_CSI_DIVISION_STATUS: 'CSI Division status has been updated',
  UPDATE_UNIFORMAT_DIVISION_STATUS: 'Unifomat Division status has been updated',
  UPDATE_CM_GC_NAME: 'CM GC Item Name has been updated',
  UPDATE_OWNER_NAME: 'Owner Name has been updated',
  UPDATE_CSI_DIVISION_CODE_NAME: 'Csi Division Code Name has been updated',
  UPDATE_UNIFORMATDIVISION_CODE: 'Uniformat division code has been updated',
  ADD_CM_GC_NAME: 'CM GC Item has been added',
  ADD_OWNERCODE_NAME: 'Owner Code has been added',
  ADD_CSIDIVISION_NAME: 'Csi Division Code has been added',
  ADD_UNIFORMATDIVISION_NAME: 'Uniformat Division Code has been added',
  UPDATE_PAY_APPLICATION_CATEGORY: 'Payment Application Categoty has been updated',
  ADD_PAY_APPLICATION_CATEGORY: 'Payment Application Created Successfully',
  DEACTIVE_PAY_APPLICATION_CATEGORY: 'Are you sure want to deactivate this Payment Application Category?',
  DEACTIVE_PAY_APPLICATION_TEMPLATE: 'Are you sure want to deactivate this Payment Application Template?',
  ACTIVE_PAY_APPLICATION_TEMPLATE: 'Are you sure want to activate this Payment Application Template?',
  ACTIVE_PAY_APPLICATION_CATEGORY: 'Are you sure want to activate this Payment Application Category?',
  UPDATE_PAY_APPLICATION_CATEGORY_STATUS: 'Payment Application Category status has been updated',
  UPDATE_PAY_APPLICATION_TEMPLATE_STATUS: 'Payment Application Template status has been updated',
  ADD_DOCUMENT_PAY_APPLICATION_CATEGORY_STATUS: 'Payment Application Category has been added',
  EDIT_DOCUMENT_PAY_APPLICATION_CATEGORY_STATUS: 'Payment Application Category has been updated',
  ADD_Update_PAY_APPLICATION_ATTACHMENTSHEET: 'Attachment data has been saved successfully',
  ADD_Update_PAY_APPLICATION_TEMPLATE: 'Payment Application Template has been created successfully',
  UPDATE_PAY_APPLICATION_TEMPLATE: 'Payment Application Template has been updated successfully',
  ADD_Update_PAY_APPLICATION_CHANGE_ORDER_LIST: 'Change Order Summary data has been saved successfully',
  DUPLICATE_BUDGETITEM_IN_PAY_APPLICATION_ATTACHMENTSHEET: 'A duplicate budget item cannot be added as it already exists within this Payment Application.',
  DUPLICATE_BUDGETITEM_IN_PAY_APPLICATION_TEMPLATE: 'A duplicate budget item cannot be added as it already exists within this Template',
  NO_BUDGET_ITEM_SELECTED: 'Please select a budget item to save the details.',
  POPOVER_DELETE_BUDGET_ITEM_TEMPLATE: 'Are you sure you want to delete Budget Item?',
  POPOVER_DELETE_CATEGORY_TEMPLATE: 'Are you sure you want to delete Payment Application Category?',
  POPOVER_DELETE_BUDGET_ITEM: ' Are you sure you want to delete the Budget Item?',
  POPOVER_DELETE_CATEGORY: 'Are you sure you want to delete the Payment Application Category?',
  POPOVER_DELETE_CHANGE_ORDER_LIST: 'Are you sure you want to delete the Change Order??',
  EMPTY_PAY_APPLICATION_ATTACHMENT_SHEET: 'The Payment Application Category cannot be saved empty.',
  EMPTY_PAY_APPLICATION_TEMPLATE_SHEET: 'The Payment Application Template cannot be saved empty.',
  EMPTY_PAY_APPLICATION_BUDGET_ITEM_ATTACHMENT_SHEET: 'The Payment Application Budget Item cannot be saved empty.',
  EMPTY_PAY_APPLICATION_TEMPLATE_ATTACHMENT_SHEET: 'The Payment Application Template Budget Item cannot be saved empty.',
  NO_BUDGET_ITEM_EXIST: 'There are no budget items in this project; kindly add them to the budget details.',
  UPDATE_PAY_APPLICATION: 'Payment Application Updated Successfully',
  SEND_PAY_APPLICATION_FOR_APPROVAL: 'Payment Application successfully sent for approval.',
  VALIDATION_OF_COLUMN_K_O: 'Total completed and stored value should not be greater than Revised scheduled value​',
  CMGC_CODE_NAME_EXISTS_MESSAGE: 'CM/GC code name already exists',
  OWNER_CODE_NAME_EXISTS_MESSAGE: 'Owner code name already exists',
  COST_EVENT_REASON_UPDATE: 'Cost event reason Updated successfully',
  COST_EVENT_REASON_DEACTIVE: 'Cost event reason has been deactivated',
  COST_EVENT_REASON_ACTIVE: 'Cost event reason has been activated',
  COST_EVENT_REASON_CREATED: 'Cost event reason created successfully',
  COST_EVENT_TYPE_CREATED: 'Cost Event Type Updated successfully',
  COST_EVENT_TYPE_ADDED: 'Cost Event type has been added',
  COST_EVENT_TYPE_ACTIVATE: 'Cost Event Type has been activated',
  COST_EVENT_TYPE_DEACTIVATE: 'Cost Event Type has been deactivated',
  ADD_CCR: 'Contingency/Buyout Change Request Added Successfully',
  UPDATE_CCR: 'Contingency/Buyout Change Request Updated Successfully',
  SEND_CCR_FOR_APPROVAL: 'Contingency/Buyout Request successfully sent for approval',
  DELETE_ASSETS_MESSAGE: 'Are you sure you want to delete project assets?',
  FILE_UPLOADED: 'File has been Uploaded',
  COMMENT_ADDED: 'Comment has been added',
  WORKFLOW_UPDATED: 'Workflow Updated successfully',
  POPOVER_CANCEL_CLAIM: 'Are you sure you want to cancel claim?',
  FORM_SUBMITTED: 'Warranty / Repair Claim has been added successfully',
  FORM_UPDATED: 'Warranty / Repair Claim has been updated successfully',
  FORM_DELETE: 'Warranty / Repair Claim has been deleted',
  ADD_ASSET_TO_PROJECT: 'Asset has been added in Project successfully',
  NO_WARRANTY: 'No warranty found for selected asset',
  ADD_ASSET: 'Asset has been added successfully',
  UPDATE_ASSET: 'Asset has been updated',
  BULK_UPDATED: 'Cost Event(s) updated successfully',
  CANCEL_CLAIM: 'Claim cancelled successfully',
  PUNCHLIST_ADDED: 'Punchlist has been added successfully',
  PUNCHLIST_UPDATED: 'Punchlist has been updated successfully',
  REQUEST_REASON_ADDED: 'Request Reason has been added successfully',
  REQUEST_REASON_UPDATED: 'Request Reason has been updated successfully',
  REQUEST_REASON_ACTIVE: 'Request Reason has been activated',
  REQUEST_REASON_DEACTIVE: 'Request Reason has been deactivated',
  REQUEST_REASON_REQUIRED: 'Request Reason is required',
  SERVICE_ADDED: 'Service has been added successfully',
  SERVICE_UPDATED: 'Service has been updated successfully',
  CONTRACT_ADDED: 'Contract has been added successfully',
  CONTRACT_UPDATED: 'Contract has been updated successfully',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Email is not valid',
  PASSWORD_REQUIRED: 'Password is required',
  PAYMENT_APPLICATION_REJECTED : 'Payment Application has been Rejected',
  PAYMENT_APPLICATION_APPROVED : 'Payment Application has been Approved',
  BUILDING_ACTIVATE: 'Are you sure you want to activate building?',
  BUILDING_DEACTIVATE: 'Are you sure you want to deactivate building?',
  PAYMENT_APPLICATION_TEMPLATE_ACTIVE: 'Payment Application Template has been activated',
  PAYMENT_APPLICATION_TEMPLATE_DEACTIVE: 'Payment Application Template has been deactivated',
  PAYMENT_APPLICATION_CATEGORY_ACTIVE: 'Payment Application Category has been activated',
  PAYMENT_APPLICATION_CATEGORY_DEACTIVE: 'Payment Application Category has been deactivated',
  ASSET_TYPE_ACTIVE: 'Are you sure you want to activate Asset Type?',
  ASSET_TYPE_DEACTIVE: 'Are you sure you want to deactivate Asset Type?',
  ASSET_CATEGORY_DEACTIVE: 'Are you sure you want to deactivate Asset Category?',
  ASSET_CATEGORY_ACTIVE: 'Are you sure you want to activate Asset Category?',
  FILE_CATEGORY_UPDATE: 'File Category has been updated',
  PUNCH_LIST_STATUS_UPDATE: 'Punchlist item request is completed',
  ASSET_CATEGORY_REQUIRED: 'Asset Category is required!',
  ASSET_CATEGORY_TYPE_REQUIRED: 'Asset Type is required!',
  STATUS_REPORT_TYPE_ADDED: 'Status Report Type added successfully',
  STATUS_REPORT_TYPE_UPDATED: 'Status Report Type updated successfully',
  STATUS_REPORT_TYPE_ACTIVE: 'Status Report Type activated',
  STATUS_REPORT_TYPE_DEACTIVE: 'Status Report Type deactivated',
  STATUS_REPORT_TYPE_ACTIVATE_CONFIRMATION : "Are you sure want to activate this status report type?",
  STATUS_REPORT_TYPE_DEACTIVATE_CONFIRMATION : "Are you sure want to deactivate this status report type?",
  PROJECT_TYPE_ADDED: 'Project Type has been added successfully',
  PROJECT_TYPE_UPDATED: 'Project Type has been updated successfully',
  OVERBUDGET_MESSAGE : "Requested amount is greater than the available amount. Do you still want to approve this Cost Event?",
  TASK_ITEM_UPDATE : "Task Item updated successfully",
  CONTEXT_DEACTIVE : "Context has been deactivated",
  CONTEXT_ACTIVE : "Context has been activated",
  FILE_CATEGORY_ACTIVE: 'File Category has been activated',
  FILE_CATEGORY_DEACTIVE: 'File Category has been deactivated',
  FUNDING_SOURCE_ACTIVE: 'Funding Source has been activated',
  FUNDING_SOURCE_DEACTIVE: 'Funding Source has been deactivated',
  FUNDING_SOURCE_ADDED: 'Funding Source has been added successfully',
  FUNDING_SOURCE_UPDATED: 'Funding Source has been updated successfully',
  FUNDING_SOURCE_CATEGORY_ACTIVE: 'Funding Source Category has been activated',
  FUNDING_SOURCE_CATEGORY_DEACTIVE: 'Funding Source Category has been deactivated',
  FUNDING_SOURCE_CATEGORY_ADDED: 'Funding Source Category has been added successfully',
  FUNDING_SOURCE_CATEGORY_UPDATED: 'Funding Source Category has been updated successfully',
  DOCUMENT_ACCEPTED: 'Document has been accepted successfully',
  DOCUMENT_REJECTED: 'Document has been rejected successfully',
  CONFIGURATION_SAVED : 'Unit Type configuration has been saved successfully',
  INVOICE_CATEGORY_ADDED: 'Invoice Category has been added successfully',
  INVOICE_CATEGORY_UPDATED: 'Invoice Category has been updated successfully',
  COST_EVENT_INFO_TOOLTIP : 'This Cost Event has multiple funding source, kindly allocate required Amount manually',
  PROJECT_EMPTY_ON_CREATE_COST_EVENT :'You do not have any Project for which cost event can be created.',
  CAMPUS_ADDED : 'Campus has been added successfully',
  CAMPUS_UPDATED : 'Campus has been updated successfully',
  BUILDING_ADDED : 'Building has been added successfully',
  BUILDING_UPDATED : 'Building has been updated successfully',
  ROOM_ADDED : 'Room has been added successfully',
  ROOM_UPDATED : 'Room has been updated successfully',
  BULK_UPDATE_SELECT_ERROR: 'Please Select Cost Events for Bulk Update first'
};

export interface ApprovalStage {
  stage: string;
  actions: {
    action: string;
    nextStage: string;
  }[];
  completedAt?: Date;
}

export const APPROVAL_FLOW: ApprovalStage[] = [
  {
    stage: 'Create Claim',
    actions: [
      { action: 'Review Pending', nextStage: 'Claim In Review' }
    ]
  },
  {
    stage: 'Claim In Review',
    actions: [
      { action: 'Accepts Claim', nextStage: 'Work In Progress' },
      { action: 'Rejects Claim', nextStage: 'Claim Rejected' }
    ]
  },
  {
    stage: 'Work In Progress',
    actions: [
      { action: 'Updates Status as "Work Completed"', nextStage: 'Work In Review' }
    ]
  },
  {
    stage: 'Work In Review',
    actions: [
      { action: 'Updates status as "Work is Satisfactory"', nextStage: 'Completed' },
      { action: 'Updates status as "Work is Not Satisfactory"', nextStage: 'Work Rejected' }
    ]
  },
  {
    stage: 'Claim Rejected',
    actions: [
      { action: 'Updates Claim and send for Acceptance', nextStage: 'Claim In Review' }
    ]
  },
  {
    stage: 'Work Rejected',
    actions: [
      { action: 'Updates Status as "Work In Progress"', nextStage: 'Work In Progress' }
    ]
  }
];

export interface ApprovalHistory {
  stage: string;
  completedAt: Date;
}
