import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './shared/authguard.guard';
import { GuestPunchlistComponent } from './pages/projects/punchlilst/guest-punchlist/guest-punchlist.component';
import { AssetDetailComponent } from './pages/master/assets/asset-detail/asset-detail.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',

  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },

  {
    path: '',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
  },

  {
    path: 'dashboard',
    canActivate: [AuthguardGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'Asset',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/assets/asset-maintenance-list/asset-maintenance-list.module').then(m => m.AssetMaintenanceListModule)
  },
  {
    path: 'ReleaseNotes',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/release-notes/relese-notes.module').then(m => m.ReleaseNotesModule)
  },


  {
    path: 'ReleaseNotes',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/release-notes/relese-notes.module').then(m => m.ReleaseNotesModule)
  },

  {
    path: 'fundingSourceCategory',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/funding-source-category/funding-source-category.module').then(m => m.FundingSourceCategoryModule)
  },

  {
    path: 'CampusFacilities',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/campus-facilities/campus-facilities.module').then(m => m.CampusFacilityModule)
  },

  {
    path: 'EntityConfig',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/entity-config/entity-config.module').then(m => m.EntityConfigModule)
  },

  {
    path: 'BuildingInfo',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/building/building.module').then(m => m.BuildingModule)
  },

  {
    path: 'SiteInfo',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/site/site.module').then(m => m.SiteModule)
  },

  {
    path: 'RoomInfo',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/room/room.module').then(m => m.RoomModule)
  },

  {
    path: 'EditAsset/:assetId',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/assets/assets.module').then(m => m.AssetsModule)
  },

  {
    path: 'Roles',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/security/roles/roles.module').then(m => m.RolesModule)
  },

  {
    path: 'Permission',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/security/permissions/permissions.module').then(m => m.PermissionsModule)
  },

  {
    path: 'projectInformation',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/project-information/project-information.module').then(m => m.ProjectInformationModule)
  },

  {
    path: 'BudgetLineSummary',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/budget-line-item-summary/budget-line-item-summary.module').then(m => m.BudgetLineItemSummaryModule)
  },

  {
    path: 'fundingSource',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/funding-source/funding-source/funding-source.module').then(m => m.FundingSourceModule)
  },

  {
    path: 'invoiceList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/invoice-list/invoice-list.module').then(m => m.InvoiceListModule)
  },

  {
    path: 'statusTypeCategory',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/status-type/status-type.module').then(m => m.StatusTypeModule)
  },

  {
    path: 'invoiceCategory',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/invoice-categories/invoice-categories.module').then(m => m.InvoiceCategoriesModule)
  },

  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },

  {
    path: 'users',
    loadChildren: () => import('./pages/users/listing/users.module').then(m => m.UsersModule)
  },

  {
    path: 'vendor',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/vendor/vendor.module').then(m => m.VendorModule)
  },

  {
    path: 'filecategory',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/add-file-category/add-file-category.module').then(m => m.AddFileCategoryModule)
  },

  {
    path: 'app-locations',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/locations/locations.module').then(m => m.LocationModule)
  },

  {
    path: 'program',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/program/program-list/program-list.module').then(m => m.ProgramListModule)
  },

  {
    path: 'contracttypes',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/contract-type/contract-types-listing/contract-types-listing.module').then(m => m.ContractTypesListingMoodulle)
  },

  {
    path: 'CommonKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/KPI/common-kpi/common-kpi.module').then(m => m.CommonKpiModule)
  },

  {
    path: 'servicetype',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/service-type/service-type-list/service-type-list.module').then(m => m.ServiceTypeListModule)
  },

  {
    path: 'projects',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/project.module').then(m => m.ProjectModule)
  },

  {
    path: 'costevents',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/costevents/costevent-tabs/costevent-tabs.module').then(m => m.CostEventTabsModule)
  },

  {
    path: 'report/issuelog',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/issuelog/issuelog.module').then(m => m.IssuelogModule)
  },

  {
    path: 'report/programweekly',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/weeklyreport/weeklyreport.module').then(m => m.WeeklyreportModule)
  },

  {
    path: 'report/projectmonthly',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/monthlyreport/monthlyreport.module').then(m => m.MonthlyreportModule)
  },

  {
    path: 'report/pmassignment',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/pmassignment/pmassignment.module').then(m => m.PmassignmentModule)
  },

  {
    path: 'report/docreviewlog',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/docreviewlog/docreviewlog.module').then(m => m.DocreviewlogModule)
  },

  {
    path: 'report/cocrlog',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/cocrlog/cocrlog.module').then(m => m.CocrlogModule)
  },

  {
    path: 'report/pmactivity',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/reports/pmactivityreport/pmactivityreport.module').then(m => m.PMActivityReportModule)
  },

  {
    path: 'product/signups',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/product/signups/signups.module').then(m => m.SignUpModule)
  },

  {
    path: 'product/users',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule)
  },

  {
    path: 'budgetitems',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/budgetItem/budget-items/budget-items.module').then(m => m.BudgetItemsModule)
  },

  {
    path: 'closeout',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/closeout/closeout-items/closeout-items.module').then(m => m.CloseoutItemsModule)
  },

  {
    path: 'taskItems',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/taskItem/task-items/task-items.module').then(m => m.TaskItemsModule)
  },

  {
    path: 'projecttypes',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/project-program-type/project-type/project-type.module').then(m => m.ProjectTypeModule)
  },

  {
    path: 'reason',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/cost-event-reason/cost-event-reason-list/cost-event-reason-list.module').then(m => m.CostEventReasonListModule)
  },

  {
    path: 'ProgramKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/program-kpi/program-kpi.module').then(m => m.ProgramKpiModule)
  },

  {
    path: 'costEventKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/costevent-kpi/costevent-kpi.module').then(m => m.CostEventKpiModule)
  },

  {
    path: 'Invoicekpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/invoice-kpi/invoice-kpi.module').then(m => m.InvoiceKpiModule)
  },

  {
    path: 'Issuekpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/issue-kpi/issue-kpi.module').then(m => m.IssueKpiModule)
  },

  {
    path: 'AgedKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/aged-kpi/aged-kpi.module').then(m => m.AgedKpiModule)
  },

  {
    path: 'BallInCourt',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/ball-in-court-report/ball-in-court-report.module').then(m => m.BallInCourtReportModule)
  },

  {
    path: 'QuantityReport',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/quantity-issued-kpi/quantity-issued-kpi.module').then(m => m.QuantityIssuedKpiModule)
  },

  {
    path: 'QuantitySummaryCSI',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/quantity-summary-csi-division/quantity-summary-csi-division.module').then(m => m.QuantitySummaryCsiDivisionModule)
  },

  {
    path: 'ProjectListByLocation',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/location-kpi/location-project-list/location-project-list.module').then(m => m.LocationProjectListModule)
  },

  {
    path: 'QuantitySummaryUniformat',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/quantity-summary-uniformat-division/quantity-summary-uniformat-division.module').then(m => m.QuantitySummaryUniformatDivisionModule)
  },

  {
    path: 'QuantitySummaryByMonth',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/quantity-summary-by-month/quantity-summary-by-month.module').then(m => m.QuantitySummaryByMonthModule)
  },

  {
    path: 'CostScheduleImapactKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/cost-impact-schedule-impact-kpi/cost-impact-schedule-impact-kpi.module').then(m => m.CostImpactScheduleImpactKpiModule)
  },

  {
    path: 'FundingPerformanceKPi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/fund-performance-kpi/fund-performance-kpi.module').then(m => m.FundPerformanceKpiModule)
  },

  {
    path: 'LocationKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/location-kpi/location-kpi.module').then(m => m.LocationKpiModule)
  },

  {
    path: 'general-task-by-user',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/general-task-byuser/general-task-byuser.module').then(m => m.GeneralTaskBuyersModule)
  },

  {
    path: 'ProjectCostCount',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/dashboard/project-cost-count/project-cost-count.module').then(m => m.ProjectCostCountModule)
  },

  {
    path: 'PublicRecordRequest',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/public-record-request/public-record-request.module').then(m => m.PublicRecordRequestModule)
  },

  {
    path: 'Allpunchlistitems',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/punchlilst/all-punchlistitems/all-punchlistitems.module').then(m => m.AllPunchlistModule)
  },

  {
    path: 'workflow',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/Workflow/workflow/workflow.module').then(m => m.WorkflowModule)
  },

  {
    path: 'adhocReport',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/adhoc-report/adhoc-report-Routing.module').then(m => m.AdHocReportRoutingModule)
  },

  {
    path: 'MasterDataConfig',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/master-data-config/master-data-config.module').then(m => m.MasterDataConfigModule)
  },

  {
    path: 'Configurations',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/configurations/configurations.module').then(m => m.ConfigurationsModule)
  },

  {
    path: 'buildingReport',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/report/buildingreport/buildingreport.module').then(m => m.BuildingReportModule)
  },

  {
    path: 'siteReport',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/report/sitereport/sitereport.module').then(m => m.SitereportModule)
  },

  {
    path: 'sportsAreaGraph',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/report/sports-graph-report/sports-graph-report.module').then(m => m.SportsGraphReportModule)
  },

  {
    path: 'FiReport',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/report/facility-report/facility-report.module').then(m => m.FacilityReportModule)
  },

  {
    path: 'requestList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./request-list/request-list.module').then(m => m.RequestListModule)
  },

  {
    path: 'resourceList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./resource-list/resource-list.module').then(m => m.ResourceListModule)
  },

  {
    path: 'requestReason',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./request-reason/request-reason.module').then(m => m.RequestReasonModule)
  },

  {
    path: 'tcrequestList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./request-list/req-list-of-tempcoordinator/req-list-of-tempcoordinator.module').then(m => m.ReqListOfTempcoordinatorModule)
  },

  {
    path: 'vendorrequestList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./request-list/req-list-for-vendor/req-list-for-vendor.module').then(m => m.VendorReqListModule)
  },

  {
    path: 'timesheetList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./timesheet-list/timesheet-list.module').then(m => m.TimesheetListModule)
  },

  {
    path: 'InvoiceList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./invoice-management-list/invoice-management-list.module').then(m => m.InvoiceManagementListModule)
  },

  {
    path: 'costevent',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/cost-event/cost-event/cost-event.module').then(m => m.CostEventModule)
  },

  {
    path: 'CampusType',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/campus-type/campus-type.module').then(m => m.CampusTypeModule)
  },

  {
    path: 'Settings',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/settings-for-email/settings-for-email.module').then(m => m.SettingsForEmailModule)
  },

  {
    path: 'Viewsite/:uniqueId',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/FacilityInventory/site-view/site-view.module').then(m => m.SiteViewModule)
  },

  {
    path: 'Buidlingview/:uniqueId',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/FacilityInventory/building-view/building-view.module').then(m => m.BuildingViewModule)
  },

  {
    path: 'issues/view/:id',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/issues/vieweditissue/vieweditissue.module').then(m => m.VieweditissueModule)
  },

  {
    path: 'workflowSettings',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/Workflow/workflow-settings/workflow-settings.module').then(m => m.WorkflowSettingsModule)
  },

  {
    path: 'DocumentList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/document-list/document-list.module').then(m => m.DocumentListModule)
  },

  {
    path: 'guest-user-projects',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/punchlilst/projects-for-guestuser/projects-for-guestuser.module').then(m => m.GuestUserProjectModule)
  },

  {
    path: 'product/customers',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/product/customers/customers.module').then(m => m.CustomerModule)
  },

  {
    path: 'audit',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/product/audit/audit.module').then(m => m.AuditModule)
  },
  {
    path: 'vendorContact',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/vendor/vendor-contact/vendor-contact.module').then(m => m.VendorContact)
  },
  {
    path: 'taskConfiguration',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/task-configuration/task-configuration/task-configuration.module').then(m => m.TaskConfigModule)
  },
  {
    path: 'project-configuration',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/Workflow/project-configurations/project-configurations.module').then(m => m.ProjectConfigurationsModule)
  },
  {
    path: 'PaymentApplicationKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/KPI/payment-application-kpi/payment-application-kpi/payment-application-kpi.module').then(m => m.PaymentApplicationKpiModule)
  },
  {
    path: 'payApplicationCategory',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/payment-application/pay-application-category/pay-application-category.module').then(m => m.PayApplicationCategoryModule)
  },
  {
    path: 'paymentApplication',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/payment-application/payment-application-list/payment-application-list.module').then(m => m.PaymentApplicationListModule)
  },
  {
    path: 'guestpunchlistitems/:id',
    component: GuestPunchlistComponent,
    data: { title: 'Guest Punch List Items' },
    canActivate: [AuthguardGuard]
  },
  {
    path: 'closeoutKpi',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/master/closeout-items-kpi/closeout-items-kpi.module').then(m => m.CloseoutItemsKpiModule)
  },
  {
    path: 'AssetDetail/:assetId',
    canActivate: [AuthguardGuard],
    component : AssetDetailComponent,
    loadChildren: () => import('./pages/master/assets/assets.module').then(m => m.AssetsModule)
  },
  {
    path: 'ccrList',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/Additional-feature/contingency-change-request/contingency-change-request.module').then(m => m.ContingencyChangeRequestModule)
  },
  {
    path: 'WarrantyDetails',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/projects/document-pay-application-category/warranty-documents/warranty-documents/warranty-documents.module').then(m => m.WarrantyDocumentsModule)
  }
];

@NgModule({
  imports: [
  CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forChild(routes),
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
