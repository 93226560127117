import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HelperService {

  @Output() openProjectEditEvent = new EventEmitter();
  tabSet: string;
  childSet: string;
  tabChangeListener = new Subject<any>();
  childTabChangeListener = new Subject<any>();
  documentChangeLister = new Subject<any>();
  parentTabListener = new Subject<Number>();
  childTabListener = new Subject<Number>();

  constructor() { }

  openProjectEdit() { 
    this.openProjectEditEvent.emit(true);
  }

  setTabString(tabS) {
    this.tabSet = tabS;
    this.tabChangeListener.next(tabS);
  }

  setdocString(docStr) {
    this.documentChangeLister.next(docStr)
  }

  getTabString() {
    return this.tabSet;
  }

  gettabStringListener() {
    return this.tabChangeListener.asObservable();
  }


  getTabChildString() {
    return this.childSet;
  }

  getChildStringListener() {
    return this.childTabChangeListener.asObservable();
  }

  getParentTabRoute() {
    return localStorage.getItem('parentTabRoute');
  }

  getChildTabRoute() {
    return localStorage.getItem('childTabRoute');
  }

  setChildTabRoute(id) {
    localStorage.setItem('childTabRoute', id);
  }

  setParentTabRoute(id) {
    localStorage.setItem('parentTabRoute', id);
  }

  setChildString(childStr) {
    this.childSet = childStr;
    this.childTabChangeListener.next(childStr);
  }

  getTabIndexFromTabString(tab: string): number {
    const tabIndexMapping = {
      costevents: 8,
      issues: 7,
      taskList: 5,
      RFID: 4,
      rfi : 3,
      submittals : 4
    };
  
    return tabIndexMapping[tab] !== undefined ? tabIndexMapping[tab] : -1; 
  }

}