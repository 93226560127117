import { MESSAGE } from "@app/pages/projects/Task-management/constants";

export function convertHoursToMinutes(hours: number): { hours: number, minutes: number } {
  return { hours: Math.floor(hours), minutes: Math.round((hours - Math.floor(hours)) * 60) };
}

export function formatTime(time: string) {
  if (this.taskDetails['totalTimeLogs'] !== null) {
    const hours = (new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getHours() < 10 ? '0' : '') + new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getHours();
    const minutes = (new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getMinutes() < 10 ? '0' : '') + new Date('2023/01/01 ' + this.taskDetails['totalTimeLogs']).getMinutes();
    time = hours + ':' + minutes;
  }
  else {
    time = '00:00';
  }

  return time;
}

export function parseValueToNumber(value: any): number {
  if (value === 0 || value === undefined || value === "") {
    return 0;
  } else {
    value = typeof value === 'string' ? value.replace('$', '') : value;
    if (typeof value === 'string' && value.startsWith('(')) {
      return -parseFloat(value?.toString().replace(/[,\(\)]/g, ''));
    } else {
      return parseFloat(value?.toString().replace(/,/g, ''));
    }
  }
}

export function formatNumber(number: number) {
  if (number < 0) {
    return `($${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
  } else {
    if (number !== null && number != undefined) {
      return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }
}

export function replaceCommaSeprateAndBracket(num: string) {
  if (num == '' || num === undefined) {
    return;
  }
  return num.replace(/[,\(\)]/g, '');
}

export function formatPercentage(number: number) {
  Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  if (number < 0) {
    const percentage = Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return '(' + percentage + ')';
  } else {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

export function formatNegativeNumber(number: number) {
  if (number < 0) {
    return `${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  } else {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

export function checkNullValuesObject(obj) {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== 0 && obj[key] !== MESSAGE.EMPTY_GUID) {
      return false;
    }
  }
  return true;
}

export function getDuration(startTime: Date | string, endTime: Date | string) {
  // Convert the input times to Date objects if they are not already
  const start: Date = new Date(startTime);
  const end: Date = new Date(endTime);

  // Calculate the difference in milliseconds
  const diff: number = end.getTime() - start.getTime();

  // Convert the difference to a more readable format
  const milliseconds: number = diff % 1000;
  const seconds: number = Math.floor((diff / 1000) % 60);
  const minutes: number = Math.floor((diff / (1000 * 60)) % 60);
  const hours: number = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const days: number = Math.floor(diff / (1000 * 60 * 60 * 24));

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds
  };
}

export function exportPdf(id, filename) {
  setTimeout(() => {
    var domToPdf = require('dom-to-pdf');
    var element = document.getElementById(id);
    var options = {
      filename: `${filename}.pdf`
    };
    domToPdf(element, options, function () {
    });
  }, 100);
}

export function formatNegativeNumberWithMinusSign(number: number) {
  return number < 0
    ? `-${Math.abs(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}