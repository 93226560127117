

export class VendorTypeList {
  vendors: VendorList[];
  message: string;
  success: boolean;
}

export class VendorList {
  isActive: boolean;
  vendorCity: string;
  vendorState: string;
  vendorId: string;
  vendorName: string;
  vendorNumber: string;
  accManageEmail: any;
  Addr1: string;
  Addr2: string;
  note: string;
  county: any;
  vendorPostalCode: string;
  status: number;
  vendorContactJson: string;
  address_line_1: string;
  address_line_2: string;
}

export class ProgramListResponse {
  message: string;
  success: boolean;
  programs: any[];
}
export class ContractTypeListResponse {
  message: string;
  success: boolean;
  contractType: any[];
}

export class CostEventTypeListResponse {
  message: string;
  success: boolean;
  costEventTypes: any[];
}

export class CostEventTypeCategoriesListResponse {
  message: string;
  success: boolean;
  costEventTypescategories: any[];
}

export class ServiceTypeListResponse {
  message: string;
  success: boolean;
  serviceType: any[];
}
export class CostEventReasonResponse {
  message: string;
  success: boolean;
  costEventReason: any[];
}

export class ProgramList {
  isActive: string;
  programName: string;
  programBudget: string;
  programDescription: string;
  programNumber: string;
  programID: string;
  createdOn: string;
  canDelete: string;
  status: number;
  isVirtual: boolean;
}
export class ContractTypeList {
  id: string;
  name: string;
}
export class ServiceTypeList {
  id: string;
  name: string;
}
export class ReasonList {
  id: string;
  name: string;
}

export class ProgramResponse {
  message: string;
  success: boolean;
  program: any[];
}

export class Program {
  programName: string;
  programNumber: string;
  programBudget: string;
  programDescription: string;
  IsVirtual: boolean;
}

export class ContractType {
  name: string;
}
export class ServiceType {
  name: string;
}
export class Reason {
  name: string;
}
export class VendorTypeListId {
  vendorData: VendorList[];
  message: string;
  success: boolean;
}

export class CmResponse {
  message: string;
  success: boolean;
  cm: any[];
}

export class BudgetValues {
  budgetItem: BudgetItem[];
  message: string;
  success: boolean;
}

export class CloseoutValues {
  closeoutItems: CloseoutItems[];
  message: string;
  success: boolean;
}

export class BudgetItem {
  budgetItemName: string;
  isActive: number;
  budgetCategoryName: string;
  budgetCategoryId: string;
}

export class CloseoutItems {
  closeOutItemName: string;
  isActive: number;
}

export class ProgramTypeListResponse {
  message: string;
  success: boolean;
  programType: any[];
}

export class ProjectTypeListResponse {
  message: string;
  success: boolean;
  projectType: any[];
}
export class FileCategory {
  FileCategoryName: string;
  FileCategoryId: number;
  FileCategoryDescription: string;
  ParentFileCategoryId: number;
  ParentFileCategoryName: string;

}

export class TaskValue {
  item: TaskItem[];
  message: string;
  success: boolean;
}

export class TaskItem {
  taskTitle: string;
  isActive: number;
  itemId: string;
  aassignTo: string;
  itemDescription: string;
  daysTocompleteTask: number;
}


export class WorkflowListResponse {
  message: string;
  success: boolean;
  Workflow: any[];
  workFlowStepList: any[];
  contractType: any[];
}
export class WorkflowList {
  id: string;
  name: string;
  description: string;
  switch: any;
  workflowType:string;
}
export class Workflow {
  id:string;
  name: string;
  description: string;
  switch: any;
  workflowType: string;
}

export class ContactPeople {
  contactPersonID:string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  address_line_1: string;
  note: any;
  address_line_2: string;
  city: string;
  state: string;
  zipcode: any;
  contactNo: any;
  county: any;
  allowLogin: boolean;
  isActive: boolean;
  id:any;
}
export class TimeSheetRequestModel{
  id: string;
  title: string;
  requestId: any;
  totalHours: number;
  startDate:any;
  endDate:any;
  resourceList: EmployeeList[];
}
export class EmployeeList {
  // startDate:any;
  // endDate:any;
  rate:any;
  resourceId : string;
  name :string;
  resourceTimeList : TimeList [];
  // total:any;
}

export class TimeList{
  id: string;
  date: any;
  startTime: any;
  lunchIn: any;
  lunchOut: any;
  finishTime: any;
  totalHours: any;
  isNoShow: boolean;
}



export class Campus {
  territory:string;
  campusId:string;
  isDisabled:boolean;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: any;
}

export class VirtualProgramList {

  programID: string;
  programName: string;
  programNumber: string;
  totalVirtualProjects: string;
  isActive: string;
  status: number;

}
export class VirtualProgramResponse {
  success: boolean;
  message: string;
  virtualProgramList: any[];
}

export class ActiveVirtualProgramResponse {
  success: boolean;
  message: string;
  virtualProgramList: any[];
}

export class GetAllProjectToVP {

  projectID: string;
  projectNumber:string;
  projectName: string;
  createdOn: string;
  isActive: string;
  estimatedCost: number;
  projectType: string;
  status: number;
  tags:string;
  isSelected:boolean;


}
export class GetAllProjectToVPResponse {
  success: boolean;
  message: string;
  virtualProgramList: any[];
}

export class AddProjectsToVP {
  programID:string;
  projectIDs: string;
  checked?:boolean;
  virtualProjectList: any[];
}

export class virtualProgramDetail{

  projectNumber:string;
  projectName:string;
  projectID:string;
  estimatedCost:string;
  createdOn:string;
  projectType:string;
  cEs:string;
  issues:string;
  isSelected:boolean;
  checked?:boolean;

}

export class virtualProjects {
  programID : string;
  projectID : string;
  projectNumber: string;
  projectName: string;
  isSelected?:boolean
}

export class virtualProgramDetailResponse {
  success: boolean;
  message: string;
  virtualProgramDetails: any[];
}

export class taskPriorityItem{
  success: boolean;
  message: string;
  item: any[];
}

export class priorityItem {
 Id:string;
 Title:string;
 Hours:number;
 ColorCode:string;
 IsActive:boolean;
 CreatedOn:string;
 UpdatedOn:string;
}

export class taskContextResponse{
  success: boolean;
  message: string;
  item: any[];
}

export class contextItem {
 Id:string;
 ContextTitle:string;
 IsActive:boolean;
 CreatedOn:string;
 UpdatedOn:string;
}


export interface CloseoutResponse {
  success: boolean
  message: string
  kpiData: KpiData
}

export interface KpiData {
  chartData: ChartDaum[]
  listData: ListDaum[]
}

export interface ChartDaum {
  status: number;
  statusName: string;
  noOfCloseOut: number;
  totalPercentage;
}

export interface ListDaum {
  closeOutItemId: string;
  closeOutItemName: string;
  divisonCode: string;
  uniformatDivisonCode?: string;
  status: number;
}

export interface BallInCourtKpiResponse {
  success: boolean;
  message: string;
  kpiData: BallInCourtKpiData;
}

export interface BallInCourtKpiData {
  chartData: ChartDaumBallInCourt[];
  listData: ListDaumBallInCourt[];
}

export interface ChartDaumBallInCourt {
  assignee: string;
  roleName: string;
  noOfCloseoutItem: number;
  totalPercentage;
}

export interface ListDaumBallInCourt {
  projectID: string;
  assignee: string;
  closeOutItemId: string;
  closeOutItemName: string;
  divisonCode: string;
  uniformatDivisonCode?: string;
  status: number;
  createdOn: string;
  roleName: string;
}

export interface BudgetCategoryResponse {
  success: boolean;
  message: string;
  budgetCategories: BudgetCategory[]
}

export interface BudgetCategory {
  budgetCategoryId: string;
  budgetCategoryName: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: Date;
  isActive: boolean;
  clientID: string;
}

export interface CmGcInfoResponce {
  success: boolean;
  message: string;
  cmGcCodeList: CmGcInfo[]
}

export interface CmGcInfo {
  budgetXCMGCCodeId: string;
  cmgcCodeName: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: Date;
  isActive: boolean;
  clientID: string;
}

export interface PayApplicationCategoryResponse {
  success: boolean;
  message: string;
  payApplicationCategoryList: PayApplicationCategoryList[]
}

export interface PayApplicationCategoryWiseBudgetItemResponse {
  success: boolean;
  message: string;
  payApplicationTemplateList: payApplicationTemplateList[];
}

export interface PayApplicationTemplateResponse {
  success: boolean;
  message: string;
  payApplicationTemplateList: payApplicationTemplateList[];
}

export interface PayApplicationCategoryList {
  status: number;
  categoryId: string;
  categoryName: string;
  createdBy: any;
  createdOn: string;
  isActive: boolean;
  isDisabled: boolean;
  clientId: string;
}

export interface payApplicationTemplateList {
  id: string;
  templateName: string;
  isActive: boolean;
}

// Define the interface for a subcategory
export interface categoryCalculations {
  id : string;
  no : string;
  payApplicationCategoryId: string;
  budgetItemId: string;
  gMPScheduledAmount: number;
  actualContractAmount: number;
  scopeChangeAmount: number;
  buyOutAmount: number;
  ownerChangeAmount: number;
  oDPAmount: number;
  ownerContingencyAmount: number;
  revisedScheduledAmount: number;
  fromPreviousApplicationAmount: number;
  thisPeriodAmount: number;
  storedMateralsAmount: number;
  totalCompletedAndStoredAmount: number;
  percentage: number;
  balanceToFinishAmount: number;
  retainageAmount: number;
  retainagePercentage: number;
  payApplicationId : string;
}

export interface PayApplicationCategoryId {
  payApplicationCategoryId : string;
  payApplicationId : string;
}

export interface PayApplicationXattachmentResponse {
  success: boolean;
  message: string;
  payApplicationXAttachmentList: PayApplicationXattachmentList[];
}

export interface PayApplicationXattachmentList {
  id: string
  payApplicationId: string;
  payApplicationCategoryId: string;
}

export interface PayApplicationAttachmentSheetListResponse {
  success: boolean
  message: string
  payApplicationAttachmentSheetList: PayApplicationAttachmentSheetList
}

export interface PayApplicationAttachmentSheetList {
  payApplicationAttachmentSheetBindingModel: PayApplicationAttachmentSheetBindingModel[]
  isFirstPayApplication: boolean
}

export interface PayApplicationAttachmentSheetBindingModel {
  id: string
  no: string
  budgetItemId: string
  payApplicationCategoryId: string
  payApplicationId: string
  gmpScheduledAmount: number
  actualContractAmount: number
  scopeChangeAmount: number
  buyOutAmount: number
  ownerChangeAmount: number
  odpAmount: number
  ownerContingencyAmount: number
  revisedScheduledAmount: number
  fromPreviousApplicationAmount: number
  thisPeriodAmount: number
  storedMateralsAmount: number
  totalCompletedAndStoredAmount: number
  percentage: number
  balanceToFinishAmount: number
  retainageAmount: number
  retainagePercentage: number
  isFromPreviousWorksheet: boolean
  isTemplateVisible: boolean
}

export interface categoryRowSumList {
  payApplicationCategoryId: string;
  budgetItemId: string;
}

export class ProjectTypeList {
  projectTypeId: string;
  projectTypeName: string;
}
