import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumericOnlyDirective } from '@app/utils/numeric-only.directive';
import { DollerSign, FilterPipe, KeysPipe, MinusSignToParens, SortByPipe, SortPipe } from './pipes';
import { ViewprojectinfoService } from '@app/pages/projects/projectinfo/viewprojectinfo/viewprojectinfo.service';
import { MasterService } from '@app/pages/master/master.service';
import { CommonCommentSectionComponent } from './common/common-comment-section/common-comment-section.component';
import { CereviewLogModule } from "../pages/costevents/cereview-log/cereview-log.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MaterialModule } from '@app/material.module';
import { ProgramProjectFilterComponent } from './common/program-project-filter/program-project-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
    declarations: [
        KeysPipe,
        FilterPipe,
        SortByPipe,
        SortPipe,
        DollerSign,
        MinusSignToParens,
        NumericOnlyDirective,
        CommonCommentSectionComponent,
        ProgramProjectFilterComponent
    ],
    exports: [
        KeysPipe,
        FilterPipe,
        SortByPipe,
        SortPipe,
        DollerSign,
        MinusSignToParens,
        NumericOnlyDirective,
        CommonCommentSectionComponent,
        DataTablesModule,
        // NgxQRCodeModule,
        ProgramProjectFilterComponent,
        NgMultiSelectDropDownModule,
        FormsModule
    ],
    providers: [ViewprojectinfoService, MasterService],
    imports: [
        CommonModule,
        CereviewLogModule,
        FormsModule,
        DataTablesModule,
        // NgxQRCodeModule,
        MaterialModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule
    ]
})
export class SharedModule { }
